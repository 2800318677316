import React from 'react'
import { graphql, Link } from 'gatsby'

import Layout from '../components/ui/layout/layout'
import Hero from '../components/ui/hero/hero'
import Header from '../components/ui/header/header'
import SEO from "../components/seo"
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'

import LeftArrow from "../assets/svg/icons/arrow-left.svg"
import RightArrow from "../assets/svg/icons/arrow-right.svg"

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        date(formatString: "DD MMMM YYYY")
        description,
        thumbnail {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      html
    }
  }
`

function Blog(props) {
  const {next, prev} = props.pageContext;
  
  const {
    breadcrumb: { crumbs },
  } = props.pageContext

  return (
    <Layout>
      <SEO title={props.data.markdownRemark.frontmatter.title} description={props.data.markdownRemark.frontmatter.description} image={props.data.markdownRemark.frontmatter.thumbnail.childImageSharp.fluid.src} />
      <Header/>
      <Hero isNews={true}>
        <Breadcrumb
          crumbs={crumbs}
          crumbLabel={props.data.markdownRemark.frontmatter.title}
        />
        <h1 className="section-hero__title">{props.data.markdownRemark.frontmatter.title}</h1>
        <time className="section-hero__meta" datetime={props.data.markdownRemark.frontmatter.date} pubdate="pubdate">{props.data.markdownRemark.frontmatter.date}</time>
      </Hero>
      <main id="content">
        <div className="page-container is-news">
          <div className="entry-content" dangerouslySetInnerHTML={{ __html: props.data.markdownRemark.html }}></div>
          <div className="page-navigation">
              <div className="page-navigation__prev">
                {prev && <span className="page-navigation__title">Prev</span>}
                {prev &&
                  <Link className="page-navigation__link" to={`/news/${prev.fields.slug}`}>
                    <LeftArrow />
                    {prev.frontmatter.title}
                  </Link>
                }
              </div>
              <div className="page-navigation__next">
                {next && <span className="page-navigation__title">Next</span>}
                {next &&
                  <Link className="page-navigation__link" to={`/news/${next.fields.slug}`}>
                    {next.frontmatter.title}
                    <RightArrow />
                  </Link>
                }
              </div>
            </div>
        </div>
      </main>
    </Layout>
  )
}

export default Blog